import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import EventImage from "../../assets/event-image1.jpeg";
import axios from "axios";
import { analytics, logEvent } from "../../config/firebaseconfig";

// Mock event data based on the provided JSON
const EventData = [
  {
    id: "1",
    event: {
      title: "Educational Expo 2K25",
      presented_by:
        "M.S Education Consultants (A division of MaheshSuchit Immigration Pvt. Ltd.)",
      date: "April 10-13, 2025",
      time: "10:00 AM – 6:00 PM",
      location: "Multiple Cities (Surat, Anand, Ahmedabad)",
      image: EventImage,
      description:
        "M.S Education Consultants proudly presents its 10th Educational Expo, where students can explore global education opportunities. With a strong record of 50k+ students sent abroad, this event connects aspiring students with top universities, scholarship opportunities, and expert guidance.",
      topics: [
        "1:1 Counselling with university representatives",
        "High Scholarships & financial aid opportunities",
        "Free IELTS, PTE, & Language CERT Vouchers",
        "Free TOEFL Study Material",
        "Assured Goodies for All Attendees",
        "50% Off on all services by M.S Education",
        "Fast VVIP Services for online registrants",
      ],
      locations: [
        {
          city: "Surat",
          date: "2025-04-10",
          venue: "Prarthna Hall, VNSGU",
        },
        {
          city: "Anand",
          date: "2025-04-12",
          venue: "Dolphin Restaurant, Nr. M.S. House, Nr. Panchal Hall",
        },
        {
          city: "Ahmedabad",
          date: "2025-04-13",
          venue: "AMA, Dr Vikram Sarabhai Marg, University Area, Ahmedabad",
        },
      ],
      presenter: {
        name: "M.S Education Consultants",
        title: "A division of MaheshSuchit Immigration Pvt. Ltd.",
        image: EventImage,
      },
      relatedEvents: [
        {
          id: "2",
          title: "Study Abroad Seminar",
          date: "May 15, 2025",
        },
        {
          id: "3",
          title: "IELTS Preparation Workshop",
          date: "June 5, 2025",
        },
      ],
    },
  },
];

export default function EventDetailPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [submitLoading, setSubmitLoading] = useState(false);

  const source = searchParams.get("source") || "";
  const sourceRemark = searchParams.get("source_remark") || "";
  const branch = searchParams.get("branch") || "1";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [activeLocation, setActiveLocation] = useState(0);
  const [educationOptions, setEducationOptions] = useState([]);
  const [examOptions, setExamOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_no: "",
    email_id: "",
    educationlevel_id: "",
    exam_id: "",
    country_id: "",
    source: source,
    source_remark: sourceRemark,
    branch: branch,
  });

  useEffect(() => {
    const handleInteraction = () => {
      logEvent(analytics, "events_page_visited", {
        timestamp: new Date().toISOString(),
      });

      // Remove listener after logging to avoid multiple triggers
      window.removeEventListener("scroll", handleInteraction);
      window.removeEventListener("click", handleInteraction);
    };

    // Add listeners for interaction
    window.addEventListener("scroll", handleInteraction);
    window.addEventListener("click", handleInteraction);

    return () => {
      window.removeEventListener("scroll", handleInteraction);
      window.removeEventListener("click", handleInteraction);
    };
  }, []);

  useEffect(() => {
    // Fetch event details using the ID
    const fetchEvent = () => {
      // Since we only have one event in our data, we'll use the first one
      const foundEvent = EventData[0]?.event;
      if (foundEvent) {
        setEvent(foundEvent);

        // Set the first location as active by default
        if (foundEvent.locations && foundEvent.locations.length > 0) {
          setActiveLocation(0);
        }
      }

      setLoading(false);
    };

    const fetchEducationOptions = () => {
      axios
        .get(
          "https://crmportal.maheshsuchit.com/teqcoder-api/last-education.php"
        )
        .then((response) => {
          if (response.data && response.data.data) {
            setEducationOptions(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching education options:", error);
        });
    };

    const fetchExamOptions = () => {
      axios
        .get("https://crmportal.maheshsuchit.com/teqcoder-api/english-exam.php")
        .then((response) => {
          if (response.data && response.data.data) {
            setExamOptions(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching exam options:", error);
        });
    };

    const fetchCountries = () => {
      axios
        .get(
          "https://crmportal.maheshsuchit.com/teqcoder-api/preferance-country.php"
        )
        .then((response) => {
          if (response.data && response.data.data) {
            setCountryOptions(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching country options:", error);
        });
    };

    // Simulate a short loading time
    setTimeout(() => {
      fetchEvent();
      fetchEducationOptions();
      fetchExamOptions();
      fetchCountries();
    }, 300);
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const response = await axios.post(
        "https://crmportal.maheshsuchit.com/teqcoder-api/add-leads.php",
        JSON.stringify(formData), // Convert form data to JSON
        {
          headers: {
            "Content-Type": "application/json", // Ensure it's sent as JSON
          },
        }
      );
      await sendToGoogleSheets();
      if (response.data.data.lead_id) {
        // Redirect user after successful submission
        setTimeout(() => {
          window.location.href = `https://crmportal.maheshsuchit.com/events/thankyou.php?lead_id=${response.data.data.lead_id}`;
          setSubmitLoading(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again.");
    }
  };

  const sendToGoogleSheets = async () => {
    try {
      formData.exam_id = examOptions.find(
        (exam) => exam.exam_id == formData.exam_id
      ).exam_name;
      formData.educationlevel_id = educationOptions.find(
        (exam) => exam.educationlevel_id == formData.educationlevel_id
      )?.educationlevel;
      formData.country_id = countryOptions.find(
        (exam) => exam.country_id == formData.country_id
      ).country;

      fetch(
        "https://script.google.com/macros/s/AKfycbwl6iT34REyWLkezhusn67cU8QSl2uuEFag_ohfZ30BjFckeVjxoGK1FFGNwROsh7lU/exec",
        {
          redirect: "follow",
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        }
      )
        .then(function (response) {
          // Check if the request was successful
          if (response) {
            return response; // Assuming your script returns JSON response
          } else {
            throw new Error("Failed to submit the form.");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800">Event not found</h2>
          <button
            onClick={() => navigate("/events")}
            className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-800"
          >
            <svg
              className="w-5 h-5 mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Back to Events
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative bg-gradient-to-r from-blue-800 to-indigo-900 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <div className="mb-8 md:mb-0">
              <button
                onClick={() => navigate("/events")}
                className="inline-flex items-center text-blue-100 hover:text-white mb-4 transition"
              >
                <svg
                  className="w-5 h-5 mr-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
                Back to Events
              </button>
              <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
                {event.title}
              </h1>
              <div className="flex items-center text-blue-100 mb-2">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <span>
                  {event.date} | {event.time}
                </span>
              </div>
              <div className="flex items-center text-blue-100">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <span>{event.location}</span>
              </div>
            </div>
            {/* <div className="flex-shrink-0 flex items-center flex-col max-w-[500px]">
              <div className="w-32 h-32 md:w-40 md:h-40 bg-white rounded-full overflow-hidden border-4 border-white shadow-lg">
                <img
                  src={event.presenter.image}
                  alt={event.presenter.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="text-center mt-2">
                <p className="text-white font-medium">{event.presented_by}</p>
                <p className="text-blue-200 text-sm">{event.presenter.title}</p>
              </div>
            </div> */}
          </div>
        </div>

        {/* Background Pattern */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden opacity-10">
          <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/4 w-96 h-96 bg-white rounded-full"></div>
          <div className="absolute bottom-0 left-0 transform -translate-x-1/3 translate-y-1/4 w-80 h-80 bg-white rounded-full"></div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="bg-white rounded-xl shadow-md overflow-hidden mb-8">
              <div className="h-64 md:h-80 bg-gray-200 relative">
                <img
                  src={event.image}
                  alt={event.title}
                  className="w-full h-full object-cover"
                />
              </div>

              <div className="p-6 md:p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">
                  About the Event
                </h2>
                <p className="text-gray-700 leading-relaxed mb-8">
                  {event.description}
                </p>

                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Event Highlights
                </h3>
                <ul className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-6">
                  {event.topics.map((topic, index) => (
                    <li key={index} className="flex items-start">
                      <svg
                        className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-gray-700">{topic}</span>
                    </li>
                  ))}
                </ul>

                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Event Locations
                </h3>
                <div className="flex flex-wrap gap-3 mb-8">
                  {event.locations.map((location, index) => (
                    <div
                      key={index}
                      className={`px-4 py-2 rounded-lg cursor-pointer ${
                        activeLocation === index
                          ? "bg-blue-600 text-white"
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                      onClick={() => setActiveLocation(index)}
                    >
                      {location.city}
                    </div>
                  ))}
                </div>

                <div className="bg-blue-50 p-4 rounded-lg">
                  <h4 className="font-semibold text-blue-800 mb-2">
                    {event.locations[activeLocation].city} Details
                  </h4>
                  <p className="text-gray-700">
                    <span className="font-medium">Date:</span>{" "}
                    {new Date(
                      event.locations[activeLocation].date
                    ).toLocaleDateString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                  <p className="text-gray-700">
                    <span className="font-medium">Venue:</span>{" "}
                    {event.locations[activeLocation].venue || "To Be Announced"}
                  </p>
                </div>
              </div>
            </div>

            {/* Related Events */}
            {/* <div className="bg-white rounded-xl shadow-md overflow-hidden p-6 md:p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                Related Events
              </h2>
              <div className="space-y-4">
                {event.relatedEvents.map((relatedEvent) => (
                  <div
                    key={relatedEvent.id}
                    className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-blue-50 transition cursor-pointer"
                    onClick={() => navigate(`/events/${relatedEvent.id}`)}
                  >
                    <div className="bg-blue-100 rounded-full p-3 mr-4">
                      <svg
                        className="w-6 h-6 text-blue-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M87V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">
                        {relatedEvent.title}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {relatedEvent.date}
                      </p>
                    </div>
                    <svg
                      className="w-5 h-5 text-gray-400 ml-auto"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            </div> */}
          </div>

          <div className="lg:col-span-1" id="registration-form">
            <div className="bg-white rounded-xl shadow-md overflow-hidden sticky top-8">
              <div className="bg-blue-600 px-6 py-4">
                <h2 className="text-xl font-bold text-white">Register Now</h2>
              </div>

              <div className="p-6">
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <input
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      placeholder="First Name*"
                      required
                      className="w-full border p-2 rounded"
                    />
                    <input
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      placeholder="Last Name*"
                      required
                      className="w-full border p-2 rounded"
                    />
                    <input
                      type="tel"
                      name="mobile_no"
                      value={formData.mobile_no}
                      onChange={handleChange}
                      placeholder="Mobile No*"
                      required
                      className="w-full border p-2 rounded"
                    />
                    <input
                      type="email"
                      name="email_id"
                      value={formData.email_id}
                      onChange={handleChange}
                      placeholder="E-Mail Id*"
                      required
                      className="w-full border p-2 rounded"
                    />

                    <select
                      name="educationlevel_id"
                      value={formData.educationlevel_id}
                      onChange={handleChange}
                      required
                      className="w-full border p-2 rounded"
                    >
                      <option value="">Last Education*</option>
                      {educationOptions.map((option) => (
                        <option
                          key={option.educationlevel_id}
                          value={option.educationlevel_id}
                        >
                          {option.educationlevel}
                        </option>
                      ))}
                    </select>

                    <select
                      name="exam_id"
                      value={formData.exam_id}
                      onChange={handleChange}
                      className="w-full border p-2 rounded"
                    >
                      <option value="">English Exam?</option>
                      {examOptions.map((option) => (
                        <option key={option.exam_id} value={option.exam_id}>
                          {option.exam_name}
                        </option>
                      ))}
                    </select>

                    <select
                      name="country_id"
                      value={formData.country_id}
                      onChange={handleChange}
                      required
                      className="w-full border p-2 rounded"
                    >
                      <option value="">Country Preference*</option>
                      {countryOptions.map((option) => (
                        <option
                          key={option.country_id}
                          value={option.country_id}
                        >
                          {option.country}
                        </option>
                      ))}
                    </select>

                    {/* Hidden fields for source, source_remark, and branch */}
                    <input
                      type="hidden"
                      name="source"
                      value={formData.source}
                    />
                    <input
                      type="hidden"
                      name="source_remark"
                      value={formData.source_remark}
                    />
                    <input
                      type="hidden"
                      name="branch"
                      value={formData.branch}
                    />
                  </div>

                  <div className="pt-4">
                    <button
                      type="submit"
                      className="w-full bg-blue-600 text-white py-3 px-4 rounded-md font-medium hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      {!submitLoading && "Register Now"}
                      {submitLoading && (
                        <div className="flex justify-center items-center w-full">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RegisterCTA />
    </div>
  );
}

function RegisterCTA() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Function to check if form is visible in viewport
    const checkFormVisibility = () => {
      const form = document.getElementById("registration-form");
      if (form) {
        const rect = form.getBoundingClientRect();
        // Hide button if form is in viewport
        const isInViewport =
          rect.top <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.bottom >= 0;
        setIsVisible(!isInViewport);
      }
    };

    // Check visibility on mount
    checkFormVisibility();

    // Set up scroll listener
    window.addEventListener("scroll", checkFormVisibility);

    // Clean up
    return () => {
      window.removeEventListener("scroll", checkFormVisibility);
    };
  }, []);

  const scrollToForm = () => {
    const form = document.getElementById("registration-form");
    if (form) {
      form.scrollIntoView({ behavior: "smooth" });
    }
  };

  // If not visible, don't render anything
  if (!isVisible) return null;

  return (
    <button
      onClick={scrollToForm}
      className="fixed lg:hidden left-10 max-md:left-5 bottom-10 bg-blue-600 text-white p-3 rounded-lg shadow-lg hover:bg-blue-700 transition-all duration-300 ease-in-out group z-50"
      aria-label="Register Now"
    >
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
        <span className="inline-block whitespace-nowrap overflow-hidden">
          Register For Free
        </span>
      </div>
    </button>
  );
}
